body {
  color: #032c3e;
  font-size: 1.5rem;
  font-family: Open Sans, sans-serif !important;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: url(/img/bg.jpg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  opacity: .4;
  z-index: -1;
}

p {
  font-size: 1rem;
}

.btn-webankieta {
  background-color: #15A6FB;
  color: #fff;
  font-weight: 500;
  min-width: 140px;
  box-sizing: border-box;
  padding: 12px 20px;
  font-size: 1.1rem;
  line-height: 1.5rem;
  border-radius: 4px;
  margin: 5px 0;
  border: none;
  text-align: left;
  transition: background-color 0.2s;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: auto;

  i {
    opacity: .8;
  }

  &:hover {
    background-color: #1694E1;
    color: #fff;

    i {
      opacity: 1;
    }
  }

  &:active {
    background-color: #1d7bb3 !important;
    color: #fff !important;

    i {
      opacity: 1;
    }
  }

}

h1 {
  font-size: 1.777rem !important;
  font-weight: 600;
  line-height: 1.5 !important;
  padding: 35px 0 20px;
  margin: 0;
  font-family: open Sans, sans-serif !important;
}

h2 {
  font-size: 1.333rem !important;
  font-weight: 600;
  line-height: 1.5 !important;
}

.dropdown-toggle {
  border: 1px solid rgba(21, 166, 251, 0.5) !important;
  color: rgba(3, 44, 62, 1) !important;
  background-color: rgba(21, 166, 251, 0.1);
  font-size: 1.1rem;
  padding: 15px;
}

.page {
  padding-bottom: 3rem;
}

.hidden {
  display: none;
}

.cookie-alert {
}

.cookie-alert {
  background-color: rgba(255, 255, 255, 0.01);
  box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.1) inset, 0 0 0 1px rgba(21, 166, 251, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  color: #032c3e;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 18px 15px;
  box-sizing: border-box;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transition: bottom .6s cubic-bezier(.445, .05, .55, .95);
  z-index: 9999;
  position: fixed;
  max-width: 510px;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;

  p {
    display: inline-block;
    font-size: .8rem !important;
    line-height: .9rem !important;
    padding: 5px !important;
    margin: 0 !important;

    a {
      color: #032c3e;
    }
  }

  .buttons {
    .btn {
      background-color: #15a6fb;
      color: #ffffff !important;
      font-size: .8rem;
      padding: 3px 10px;
      border: none;
      font-weight: 600;
    }

    .btn--secondary {
      background-color: rgba(21, 166, 251, 0.1);
      color: rgba(3, 44, 62, 1) !important;
      margin-right: 10px;
    }
  }
}